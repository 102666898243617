import styled from "styled-components";

export const TetrisBodyImg = styled.img`
  overflow: hidden;
  position: absolute;
  bottom: 0;
  height: 600px;
  width: 600px;
  left: 40px;
  z-index: 0;
  border-top-right-radius: 65px;
  border-top-left-radius: 65px;
  -webkit-box-shadow: 2px -11px 22px 0px rgba(18, 18, 18, 1);
  -moz-box-shadow: 2px -11px 22px 0px rgba(18, 18, 18, 1);
  box-shadow: 2px -11px 22px 0px rgba(18, 18, 18, 1);

  @media (max-width: 1024px) {
    left: 10px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    height: 339px;
    width: 324px;
    bottom: 2px;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    -webkit-box-shadow: 2px -11px 22px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 2px -11px 22px 0px rgba(0, 0, 0, 1);
    box-shadow: 2px -11px 22px 0px rgba(0, 0, 0, 1);
  }

  @media (max-width: 320px) {
    height: 270px;
    width: 260px;
  }
`;
