import styled from "styled-components";

export const StyledNavbar = styled.div`
  position: fiexed !important;
  top: 0;

  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  margin: 0;

  box-sizing: border-box;
  background: rgb(18, 18, 18);
  background: -moz-linear-gradient(
    180deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(144, 144, 144, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(144, 144, 144, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(144, 144, 144, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#121212",endColorstr="#909090",GradientType=1);

  h1 {
    font-family: Pixel, Arial;
    font-size: 1rem;
    color: white;
    margin: 5px;
    cursor: default;
  }

  .logo {
    widht: 30px;
    height: 30px;
    margin-right: 5px;
  }

  @media (max-width: 1200px) {
    height: 50px;
  }
`;
