import styled from "styled-components";

export const StyledControlPanel = styled.div`
    margin: 40px 10px;

    h1{
        color: white;
        text-align: center;
        margin-bottom: 20px;
        cursor: default;

        font-family: Pixel, Arial, Helvetica, sans-serif;
        font-size: 1rem;
        line-height: 1.1rem;
    }

    .control-list{
        display: flex!important;
        justify-content: center;
        align-items: flex-end;

        padding: 0px

        .inner-list {
            display: flex;
            flex-flow: column nowrap;
            jusitfy-content: center;
            align-items: flex-end;

            padding: 0px;
            
            &li{
                position: relative;
                margin: 5px;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .control-list img{
        width: 60%;
        height: 60%;

        object-fit: contain;

        @media (max-width: 720px) {
            width: 40%;
            height: 40%;
        }
    }

    .control-list li{
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        line-height: 80px;
        font-size: 22px;
        text-align: center;

        width: 60px;
        height: 60px;

        color: #555;
        cursor: pointer;
        margin: 5px;

        border-color: #f2f2f2;
        border-style: solid;
        text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
        border-width: 1px;
        border-radius: 10px;

        background: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--primary-dark-color) 100%);
        font-family: sans-serif;
        transition: box-shadow 0.3s ease, transform 0.15s ease;
        box-shadow: 0 0 1px #888,0 1px 0 #fff, 0 6px 0 #C0C0C0, 0 8px 17px rgba(#444, 0.4), 2px 1px 4px rgba(#444, 0.25), -2px 1px 4px rgba(#444, 0.25), 0 9px 16px rgba(#444, 0.1);
        
        &:hover,
        &:focus {
          box-shadow: 0 0 1px #888,0 1px 0 #fff, 0 4px 0 #C0C0C0, 0 2px 35px rgba(#444, 0.3), 2px 2px 4px rgba(#444, 0.25), -2px 2px 4px rgba(#444, 0.25), 0 7px 4px rgba(#444, 0.1);
          transform: translateY(2px);
        }
        
        &:active {
          box-shadow: 0 0 1px #888,0 1px 0 #fff, 0 0 0 #C0C0C0, 0 0px 30px rgba(#444, 0.15), 2px 2px 4px rgba(#444, 0.25), -2px 2px 4px rgba(#444, 0.25), 0 0px 4px rgba(#444, 0.25);
          transform: translateY(4px); 
        }

        @media (max-width: 720px) {
            width: 50px;
            height: 50px;
        }

      }
    }
`;
