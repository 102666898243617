import styled from "styled-components";

export const StyledGameOverFoil = styled.div`
  display: grid;
  place-items: center;

  border: 2px solid #333;
  height: 325px;
  width: 225px;

  background: rgba(0, 0, 0, 0.7);
  text-align: center;

  position: absolute;
  bottom: 140px;
  left: 232px;
  border-radius: 4px;

  z-index: 20;
  cursor: default;

  h2 {
    font-family: Pixel, Arial;
    font-size: 1rem;
    color: var(--primary-color);
    margin: 5px;

    @media (max-width: 720px) {
      font-size: 0.7rem;
    }
  }

  h3 {
    font-family: Pixel, Arial;
    font-size: 0.8rem;
    color: white;
    margin: 10px 5px;

    @media (max-width: 720px) {
      font-size: 0.6rem;
    }
  }

  @media (max-width: 1024px) {
    left: 22px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    height: 183px;
    width: 120px;
    bottom: 80px;
    left: 17px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 320px) {
    height: 145px;
    width: 95px;
    bottom: 65px;
    left: 15px;
    right: 10px;
  }
`;
