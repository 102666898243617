import React from "react";
import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tetris from "./components/Tetris";

const TRACKING_ID = "G-K8DN13VL1P";
ReactGA.initialize(TRACKING_ID);

const App = () => (
  <div className="App">
    <Tetris />
    <ToastContainer theme="dark" position="top-left" />
  </div>
);

export default App;
