import styled from "styled-components";

export const StyledStage = styled.div`
  display: grid;

  grid-template-rows: repeat(${(props) => props.height}, 1fr);
  grid-template-columns: repeat(${(props) => props.width}, 1fr);
  grid-gap: 1px;

  border: 2px solid #333;
  height: 325px;
  width: 225px;

  position: absolute;

  bottom: 140px;
  left: 232px;
  border-radius: 2px;

  z-index: 10;

  @media (max-width: 1024px) {
    left: 22px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    height: 183px;
    width: 120px;
    bottom: 80px;
    left: 17px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 320px) {
    height: 145px;
    width: 95px;
    bottom: 65px;
    left: 15px;
    right: 10px;
  }
`;
