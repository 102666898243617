import styled from "styled-components";

export const StyledDisplay = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 10px 10px 7px 10px;
  border: 2px solid #333;
  width: 100%;
  border-radius: 20px;
  color: ${(props) => (props.gameOver ? "var(--primary-color)" : "#999")};
  text-align: ${(props) => (props.gameOver ? "center" : "left")};
  background: rgba(0, 0, 0, 0.5);
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  cursor: default;

  @media (max-width: 720px) {
    margin: 0 0 10px 0;
  }
`;
