import React, { useState } from "react";
import { animateScroll } from "react-scroll";

import { toast } from 'react-toastify';
import { checkCollision, createStage } from "../gameHelpers";
import { PauseImg } from "./styles/PauseImg";
import { StyledTetris, StyledTetrisWrapper } from "./styles/StyledTetris";
import { TetrisBodyImg } from "./styles/TetrisBodyImg";

// Custom Hooks
import { useGameStatus } from "../hooks/useGameStatus";
import { useInterval } from "../hooks/useInterval";
import { usePlayer } from "../hooks/usePlayer";
import { useStage } from "../hooks/useStage";

// Components
import ControlPanel from "./ControlPanel";
import Display from "./Display";
import GameOverFoil from "./GameOverFoil";
import Navbar from "./Navbar";
import PauseButton from "./PauseButton";
import Stage from "./Stage";
import StartButton from "./StartButton";

const Tetris = () => {
  const [dropTime, setDropTime] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [pauseButtonVisible, setPauseVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [pauseText, setPauseText] = useState("");

  const [player, updatePlayerPos, resetPlayer, playerRotate] = usePlayer();
  const [stage, setStage, rowsCleared] = useStage(player, resetPlayer);
  const [score, setScore, rows, setRows, level, setLevel] =
    useGameStatus(rowsCleared);

  const levelUpToast = () => toast.success("Level Up!");
  const gameOverToast = () => toast.error("Game Over!");

  const movePlayer = (dir) => {
    if (!checkCollision(player, stage, { x: dir, y: 0 })) {
      updatePlayerPos({ x: dir, y: 0 });
    }
  };

  const keyUp = ({ keyCode }) => {
    if (!gameOver) {
      if (keyCode === 40) {
        setDropTime(1000 / (level + 1));
      }
    }
  };

  function scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "tetris",
    });
  }

  const startGame = () => {
    scrollToBottom();
    setStage(createStage());
    setDropTime(1000);
    resetPlayer();
    setScore(0);
    setLevel(1);
    setRows(0);
    setGameOver(false);

    setPauseVisible(true);
    setPauseText("Pause");
    setIsPaused(false);
  };

  const pauseGame = () => {
    if (isPaused) {
      setDropTime(1000 / (level + 1));
      setPauseText("Pause");
      setIsPaused(false);
    } else {
      setDropTime(null);
      setPauseText("Continue");
      setIsPaused(true);
    }
  };

  const drop = () => {
    if (rows > (level + 1) * 2) {
      setLevel((prev) => prev + 1);
      setDropTime(1000 / (level + 1) + 200);
      levelUpToast();
    }

    if (!checkCollision(player, stage, { x: 0, y: 1 })) {
      updatePlayerPos({ x: 0, y: 1, collided: false });
    } else {
      if (player.pos.y < 1) {
        setGameOver(true);
        setDropTime(null);
        setPauseVisible(false);
        gameOverToast();
      }
      updatePlayerPos({ x: 0, y: 0, collided: true });
    }
  };

  const dropPlayer = () => {
    setDropTime(null);
    drop();
  };

  useInterval(() => {
    drop();
  }, dropTime);

  // Pc controls
  const move = ({ keyCode }) => {
    if (!gameOver && !isPaused) {
      if (keyCode === 37) {
        movePlayer(-1);
      } else if (keyCode === 39) {
        movePlayer(1);
      } else if (keyCode === 40 && !isPaused) {
        dropPlayer();
      } else if (keyCode === 38) {
        playerRotate(stage, 1);
      }
    } else {
      if (keyCode === 40 && isPaused) {
        setDropTime(null);
      }
    }
  };

  // Mobile controls
  const moveLeft = () => {
    if (!gameOver && !isPaused) {
      movePlayer(-1);
    }
  };

  const moveRight = () => {
    if (!gameOver && !isPaused) {
      movePlayer(1);
    }
  };

  const moveDown = () => {
    if (!gameOver && !isPaused) {
      dropPlayer();
      setDropTime(1000 / (level + 1));
    }
  };

  const rotateTetromino = () => {
    if (!gameOver && !isPaused) {
      playerRotate(stage, 1);
    }
  };

  return (
    <StyledTetrisWrapper
      role="button"
      tabIndex="0"
      onKeyDown={(e) => move(e)}
      onKeyUp={keyUp}
    >
      <Navbar />
      <TetrisBodyImg src={require("../img/tetris.webp")} id="tetris" />
      <StyledTetris>
        {gameOver ? <GameOverFoil score={score} /> : ""}

        {isPaused ? <PauseImg src={require("../img/pause.png")} /> : ""}
        <Stage stage={stage} />
        <aside>
          {gameOver ? (
            <div>
              <Display text={`Score: ${score}`} />
              <Display gameOver={gameOver} text="Game Over" />
            </div>
          ) : (
            <div>
              <Display text={`Score: ${score}`} />
              <Display text={`rows: ${rows}`} />
              <Display text={`Level: ${level}`} />
            </div>
          )}
          <StartButton callback={startGame} text="Start game" />
          {pauseButtonVisible ? (
            <PauseButton callback={pauseGame} text={pauseText} />
          ) : (
            ""
          )}

          <ControlPanel
            moveLeftCallback={moveLeft}
            moveRightCallback={moveRight}
            moveDownCallback={moveDown}
            rotateCallback={rotateTetromino}
          />
        </aside>
      </StyledTetris>
    </StyledTetrisWrapper>
  );
};

export default Tetris;
