import React from "react";
import { StyledNavbar } from "./styles/StyledNavbar";

const Navbar = () => (
  <StyledNavbar>
    <a href="https://www.matesera.com/" target="blank">
      <img src={require("../img/sm-logo.svg")} className="logo" />
    </a>
    <h1>Retro Tetris</h1>
  </StyledNavbar>
);

export default Navbar;
