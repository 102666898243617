import React from "react";
import { StyledControlPanel } from "./styles/StyledControlPanel";

const ControlPanel = ({
  moveLeftCallback,
  moveRightCallback,
  moveDownCallback,
  rotateCallback,
}) => {
  return (
    <StyledControlPanel>
      <h1>Controls</h1>

      <ul className="control-list">
        <li onClick={moveLeftCallback}>
          <img src={require("../img/arrow-left.png")} />
        </li>
        <ul className="inner-list">
          <li onClick={rotateCallback}>
            <img src={require("../img/rotate.png")} />
          </li>
          <li onClick={moveDownCallback}>
            <img src={require("../img/arrow-down.png")} />
          </li>
        </ul>
        <li onClick={moveRightCallback}>
          <img src={require("../img/arrow-right.png")} />
        </li>
      </ul>
    </StyledControlPanel>
  );
};

export default ControlPanel;
