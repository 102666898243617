import styled from "styled-components";
// BG Image
import bgImage from "../../img/tetris-bg3.webp";

export const StyledTetrisWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url(${bgImage}) #272729;
  background-size: cover;
  background-position: center bottom;
  overflow: hidden;
  outline: none;
  z-index: 0;

  aside {
    position: absolute;
    bottom: 10%;
    left: 55%;
    width: 300px;
    display: block;
    padding: 0 20px;
    z-index: 0;

    @media (max-width: 1200px) {
      left: 65%;
    }

    @media (max-width: 1024px) {
      position: relative;
      left: 0px;
      padding: 20px;
      margin: 0 auto;
    }

    @media (max-width: 720px) {
      width: 80%;
    }

    @media (max-width: 400px) {
      width: 95%;
    }
  }
`;
export const StyledTetris = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10% 40px;
  margin: 0 auto;
  max-width: 900px;
  z-index: 0;
  @media (max-width: 1200px) {
    margin-bottom: 600px;
    padding: 20px;
  }

  @media (max-width: 720px) {
    margin-bottom: 300px;
    padding: 0px 20px;
  }

  @media (max-width: 320px) {
    margin-bottom: 240px;
  }
`;
