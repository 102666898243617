import React from "react";
import { StyledGameOverFoil } from "./styles/StyledGameOverFoil";

const GameOverFoil = ({ score }) => (
  <StyledGameOverFoil>
    <div>
      <h2>Game Over</h2>
      <h3>Score: {score}</h3>
    </div>
  </StyledGameOverFoil>
);

export default GameOverFoil;
