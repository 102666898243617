import styled from "styled-components";

export const StyledStartButton = styled.button`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  padding: 10px 10px 7px 10px;
  width: 100%;
  border-radius: 20px;
  border: none;
  color: white;
  background: var(--primary-color);
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.1rem;
  outline: none;
  cursor: pointer;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:hover {
    background: var(--primary-dark-color);
  }

  @media (max-width: 720px) {
    margin: 0 0 10px 0;
  }
`;
