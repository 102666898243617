import styled from "styled-components";

export const PauseImg = styled.img`
  position: absolute;
  margin: auto;
  position: absolute;
  bottom: 280px;
  left: 310px;

  height: 70px;
  width: 70px;
  z-index: 20;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;

  @media (max-width: 1024px) {
    left: 10px;
    right: 10px;
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    height: 40px;
    width: 40px;

    left: 15px;
    right: 10px;
    bottom: 150px;
    margin: 0 auto;
  }
`;
